import {
  AccessTypes,
  ApiType,
  SeverityClassification,
} from '@portals/shared/admin/ApiDto';
import { URL_MATCHER } from '@portals/shared/common/matchers';
import { INVALID_URL } from '@portals/shared/common/messages';
import { array, boolean, literal, object, string, z } from 'zod';

export const apiEnvironmentForm = z.object({
  slug: z.string().min(1),
  name: z.string().min(1),
  isInternal: z.boolean(),
  isDefault: z.boolean(),
});

export type ApiEnvironmentFormInput = z.input<typeof apiEnvironmentForm>;
export type ApiEnvironmentFormOutput = z.output<typeof apiEnvironmentForm>;

export const apiForm = object({
  id: string().optional(),
  description: string().max(400).optional(),
  icon: string().default('api'), // TODO: remove
  availableInLiveMode: boolean().default(false),
  attachable: boolean().default(false),
  name: string().min(1).max(120),
  slug: string().min(1).max(120),
  productPageUrl: string()
    .max(120)
    .regex(URL_MATCHER, INVALID_URL)
    .optional()
    .or(literal('')),
  stage: z
    .string()
    .and(
      z.enum([
        'launched',
        'upcoming',
        'lab',
        'deprecated',
        'unpublished',
        'review',
      ]),
    ),
  approvalStage: z.string().and(z.enum(['test', 'live', 'public'])),
  organizations: string().array(),
  teams: string().array(),
  enableStatus: boolean().default(false),
  isPublic: boolean().default(false),
  liveModeWebhookSecret: string().optional(),
  testModeWebhookSecret: string().optional(),
  liveModeWebhookUrl: string().optional(),
  testModeWebhookUrl: string().optional(),
  classification: z
    .string()
    .and(
      z.enum([
        'system',
        'domain',
        'experience',
        'technical',
        'public',
        'partner',
      ]),
    ),
  tags: array(string()),
  environments: array(apiEnvironmentForm).min(1),
  ordering: string()
    .regex(/\d+/, 'Must be an integer')
    .default('999')
    .or(literal('')),
  termsOfUse: string().optional(),
  deprecated: boolean().default(false),
  scopesToDelete: string().array().optional(),
  appId: string(),
  accessTypes: string()
    .and(z.enum([AccessTypes.API_KEYS, AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM]))
    .array()
    .nullable(),
  serviceNowIdentifier: string().nullable(),
  severityClassification: string()
    .and(
      z.enum([
        SeverityClassification.CONFIDENTIAL,
        SeverityClassification.INTERNAL,
        SeverityClassification.OPEN,
        SeverityClassification.STRICTLY_CONFIDENTIAL,
      ]),
    )
    .nullable(),
  piiExposed: boolean().default(false),
  gdprCompliant: boolean().default(false),
  enableServiceNowRequest: boolean().default(false),
  enableFaq: boolean().default(false),
  type: z.nativeEnum(ApiType).default(ApiType.REST),
});

export type ApiFormOutput = z.output<typeof apiForm>;
export type ApiFormInput = z.input<typeof apiForm>;

export interface ApiStatisticsDetailLevel {
  public: number;
  internal: number;
  partner: number;
  unexposed: number;
}
export interface StageCount {
  launched: number;
  upcoming: number;
  lab: number;
  deprecated: number;
  unpublished: number;
  review: number;
}

export interface ApiRow {
  id: string;
  name: string;
  slug: string;
  stage: string;
  classification: string;
  isPublic: string;
  exposure: string;
  createdAt: Date;
  tags: string[];
  owner: string;
  type: ApiType;
}
