import { Button, DatePicker, P, Space, ToggleButton } from '@dnb/eufemia';
import { useCallback, useState } from 'react';

export type USER_FILTER_VALUES = 'internal' | 'external';
export type LiveModeFilter = 'live' | 'test' | 'all' | '';

export type Key =
  | 'excludeUserGroup'
  | 'loginFrom'
  | 'registeredBefore'
  | 'liveModeFilter';

export type Filters = {
  excludeUserGroup?: USER_FILTER_VALUES[];
  loginFrom?: string;
  registeredBefore?: string;
  registeredFrom?: string;
  queryString: string;
  liveModeFilter?: LiveModeFilter;
};

type FilterObject = {
  name: string;
  value: USER_FILTER_VALUES | LiveModeFilter;
};

const userFilters: FilterObject[] = [
  { name: 'Internal', value: 'internal' },
  { name: 'External', value: 'external' },
];

const liveModeFilters: FilterObject[] = [
  { name: 'Live Access', value: 'live' },
  { name: 'Test Access', value: 'test' },
  { name: 'Both', value: 'all' },
];

type FilterGroup = {
  label: string;
  key: Key;
  variant: 'radio' | 'checkbox' | 'date';
  multiselect: boolean;
  filters?: FilterObject[];
};

const all: FilterGroup[] = [
  {
    label: 'Only show users:',
    key: 'excludeUserGroup',
    variant: 'checkbox',
    multiselect: true,
    filters: userFilters,
  },
  {
    label: 'Only show users with:',
    key: 'liveModeFilter',
    variant: 'radio',
    multiselect: false,
    filters: liveModeFilters,
  },
  {
    label: 'Last logged after:',
    key: 'loginFrom',
    variant: 'date',
    multiselect: false,
  },
  {
    label: 'Register before:',
    key: 'registeredBefore',
    variant: 'date',
    multiselect: false,
  },
];

type UserFilterProps = {
  onApply: (filter: Filters) => void;
  close: () => void;
  filter: Filters;
  hideDateFilter: boolean;
};

export default function UserFilters({
  onApply,
  filter,
  close,
  hideDateFilter,
}: UserFilterProps) {
  const [oldFilter, setOldFilter] = useState<Filters>(filter);

  const onFilterChange = useCallback(
    (newValue: string | string[], key: Key) =>
      setOldFilter((old) => ({
        ...old,
        [`${key}`]: newValue,
      })),
    [],
  );

  return (
    <>
      {all
        .filter((group) => (hideDateFilter ? group.variant !== 'date' : group))
        .map((group, index) => {
          return group.variant === 'date' ? (
            <div>
              <P bottom="xx-small" top="large">
                {group.label}
              </P>
              <DatePicker
                date={
                  oldFilter[`${group.key}`]
                    ? (oldFilter[`${group.key}`] as string)
                    : undefined
                }
                label=""
                on_change={({ date }) => {
                  onFilterChange(date?.toString() ?? '', group.key);
                }}
                on_reset={() => {
                  onFilterChange('', group.key);
                }}
                show_cancel_button
                show_input
                show_reset_button
              />
            </div>
          ) : (
            <ToggleButton.Group
              key={`${group.key}-${group.label}-${index}`}
              label={group.label}
              label_direction="vertical"
              multiselect={group.multiselect}
              on_change={({ value, values }) => {
                group.multiselect
                  ? onFilterChange(values, group.key)
                  : onFilterChange(value, group.key);
              }}
              top={index == 0 ? 0 : 'small'}
              value={oldFilter[`${group.key}`]}
              values={oldFilter[`${group.key}`]}
              variant={group.variant}
            >
              {group.filters?.map(({ name, value }) => (
                <ToggleButton
                  key={`${group.key}-${value}-${name}`}
                  value={value}
                >
                  {name}
                </ToggleButton>
              ))}
            </ToggleButton.Group>
          );
        })}
      <Space top="large">
        <Button
          on_click={() => {
            onApply(oldFilter);
            close();
          }}
          size="large"
          text="Apply"
        />
      </Space>
    </>
  );
}
