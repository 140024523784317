import { DatePickerEvent } from '@dnb/eufemia/components/DatePicker';
import {
  type Controller,
  ErrorContext,
  type FormValues,
  type Schema,
  type SchemaOutput,
  type SchemaPath,
  useForm,
  useSafeState,
} from '@portals/shared-frontend/hooks';
import { debounce, deepGet } from '@portals/shared-frontend/utils';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import {
  ChangeEvent,
  type FormEvent,
  useCallback,
  useContext,
  useRef,
} from 'react';

import { useMemoizedThunk, useMemoizedThunkWeak } from './useThunk';

/**
 * One giant hack to make Eufemia form elements behave more consistently
 *
 * TODO: Should be refactored
 */

interface HandleSubmitCallback<T extends Schema> {
  (data: SchemaOutput<T>): void;
}

interface HandleSubmit<T extends Schema> {
  (
    callback: HandleSubmitCallback<T>,
  ): ({ event }: { event: FormEvent }) => void;
}

type BaseProps<T = Record<never, unknown>> = T &
  Omit<
    {
      on_change(event: { value: string }): void;
      status?: string | null;
      value?: string;
    },
    keyof T
  >;

type InputProps = BaseProps;

type TextareaProps = BaseProps;

type DropdownProps = BaseProps;

type AutocompleteProps = BaseProps<{
  value?: number | string;
  on_change(event: { value?: number | string }): void;
}>;

type CheckboxProps = BaseProps<{
  checked?: boolean;
  on_change(event: { checked: boolean }): void;
}>;

type RadioProps = CheckboxProps;

type RadioGroupProps = BaseProps;

type DatePickerProps = BaseProps<{
  date?: string;
  on_change(event: DatePickerEvent<ChangeEvent<HTMLInputElement>>): void;
}>;

type ToggleButtonProps = CheckboxProps;

type ToggleButtonGroupProps = BaseProps<{
  values?: string[];
  multiselect?: boolean;
  on_change(event: { value?: string; values?: string[] }): void;
}>;

type SliderProps = BaseProps<{
  value?: number;
  on_change(event: { value?: string; values?: string[] }): void;
}>;

interface PropFn<T extends Schema, U, O = never> {
  <Path extends string>(path: SchemaPath<T, Path>, options?: O): U;
}

interface EufemiaProps<T extends Schema> {
  input: PropFn<T, InputProps>;
  textarea: PropFn<T, TextareaProps>;
  checkbox: PropFn<T, CheckboxProps>;
  radio: PropFn<T, RadioProps>;
  radioGroup: PropFn<T, RadioGroupProps>;
  dropdown: PropFn<T, DropdownProps>;
  autocomplete: PropFn<T, AutocompleteProps>;
  slider: PropFn<T, SliderProps>;
  datePicker: PropFn<T, DatePickerProps>;
  toggleButton: PropFn<T, ToggleButtonProps>;
  toggleButtonGroup: PropFn<
    T,
    ToggleButtonGroupProps,
    { multiselect?: boolean }
  >;
}

interface ChangeHandlerMap {
  input: InputProps['on_change'];
  textarea: TextareaProps['on_change'];
  checkbox: CheckboxProps['on_change'];
  radio: RadioProps['on_change'];
  radioGroup: RadioGroupProps['on_change'];
  dropdown: DropdownProps['on_change'];
  autocomplete: AutocompleteProps['on_change'];
  slider: SliderProps['on_change'];
  datePicker: DatePickerProps['on_change'];
  toggleButton: ToggleButtonProps['on_change'];
  toggleButtonGroup: ToggleButtonGroupProps['on_change'];
  toggleButtonGroupMultiselect: ToggleButtonGroupProps['on_change'];
}

function stringValue(value: unknown): string {
  if (value == null) {
    return '';
  }

  if (typeof value === 'object') {
    return value.toString();
  }

  if (typeof value === 'string') {
    return value;
  }

  return '';
}

export function useEufemiaProps<T extends Schema>(
  controller: Controller<T>,
): EufemiaProps<T> {
  const {
    values,
    errors,
    errorsRef,
    setValue: _setValue,
    getValue,
    triggerValidation,
  } = controller;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidation = useCallback(debounce(triggerValidation, 50), [
    triggerValidation,
  ]);

  const setValue: typeof controller.setValue = useCallback(
    (path, value) => {
      if (value === getValue(path)) {
        return;
      }

      _setValue(path, value);

      if (errorsRef.current[path]) {
        debouncedValidation(path);
      }
    },
    [_setValue, debouncedValidation, errorsRef, getValue],
  );

  const getChangeHandler: PropFn<T, ChangeHandlerMap> = useMemoizedThunk(
    (path) => ({
      input({ value }) {
        setValue(path, value);
      },
      textarea({ value }) {
        setValue(path, value);
      },
      checkbox({ checked }) {
        setValue(path, checked);
      },
      radio({ checked }) {
        setValue(path, checked);
      },
      radioGroup({ value }) {
        setValue(path, value);
      },
      dropdown({ value }) {
        setValue(path, value);
      },
      autocomplete({ value }) {
        setValue(path, value);
      },
      slider({ value }) {
        setValue(path, value);
      },
      datePicker({ date }) {
        setValue(path, date ?? '');
      },
      toggleButton({ checked }) {
        setValue(path, checked);
      },
      toggleButtonGroup({ value }) {
        setValue(path, value);
      },
      toggleButtonGroupMultiselect({ values }) {
        setValue(path, values);
      },
    }),
    [setValue],
  );

  const extendBaseProps = <T,>(path: string, props: T): T => {
    const copy: T = {
      status: errors[path],
      ...props,
    };

    return copy;
  };

  const factories: EufemiaProps<T> = {
    input: (path) => {
      const value = deepGet(values, path);
      return extendBaseProps(path, {
        value: stringValue(value),
        on_change: getChangeHandler(path).input,
      });
    },
    textarea: (path) => {
      const value = deepGet(values, path);
      return extendBaseProps(path, {
        value: stringValue(value),
        on_change: getChangeHandler(path).textarea,
      });
    },
    checkbox: (path) => {
      return extendBaseProps(path, {
        checked: !!deepGet(values, path),
        on_change: getChangeHandler(path).checkbox,
      });
    },
    radio: (path) => {
      return {
        checked: !!deepGet(values, path),
        on_change: getChangeHandler(path).radio,
      };
    },
    radioGroup: (path) => {
      const value = deepGet(values, path);
      return extendBaseProps(path, {
        value: stringValue(value),
        on_change: getChangeHandler(path).radioGroup,
      });
    },
    dropdown: (path) => {
      const value = deepGet(values, path);
      return extendBaseProps(path, {
        value: stringValue(value),
        on_change: getChangeHandler(path).dropdown,
      });
    },
    autocomplete(path) {
      const value = deepGet(values, path);
      return {
        value:
          typeof value === 'number' || typeof value === 'string'
            ? value
            : undefined,
        on_change: getChangeHandler(path).autocomplete,
      };
    },
    slider: (path) => {
      const value = deepGet(values, path);
      return extendBaseProps(path, {
        value: typeof value === 'number' ? value : undefined,
        on_change: getChangeHandler(path).slider,
      });
    },
    datePicker: (path) => {
      const value = deepGet(values, path);
      return extendBaseProps(path, {
        date: stringValue(value),
        on_change: getChangeHandler(path).datePicker,
      });
    },
    toggleButton: (path) => {
      return extendBaseProps(path, {
        checked: !!deepGet(values, path),
        on_change: getChangeHandler(path).toggleButton,
      });
    },
    toggleButtonGroup: (path, options = {}) => {
      const value = deepGet(values, path);
      const changeHandler = options.multiselect
        ? getChangeHandler(path).toggleButtonGroupMultiselect
        : getChangeHandler(path).toggleButtonGroup;

      return extendBaseProps(path, {
        value: options.multiselect ? undefined : stringValue(value),
        values: options.multiselect
          ? Array.isArray(value)
            ? value
            : []
          : undefined,
        multiselect: !!options.multiselect,
        on_change: changeHandler,
      });
    },
  };

  return factories;
}

interface ExtendedController<T extends Schema> {
  controller: Controller<T>;
  handleSubmit: HandleSubmit<T>;
  register: EufemiaProps<T>;
  submitting: boolean;
}

export function useEufemiaForm<T extends Schema>(
  schema: T,
  initialValues: FormValues<T>,
): ExtendedController<T> {
  const [, setGlobalError] = useContext(ErrorContext);
  const controller = useForm(initialValues, schema);
  const register = useEufemiaProps(controller);
  const [submitting, setSubmitting] = useSafeState(false);
  const submitCounter = useRef(0);

  const { validate, setErrors, setFormError } = controller;

  const handleBackendError = useCallback(
    (error: unknown) => {
      if (ApiError.isApiError(error)) {
        if (error.body.code === 'ValidationError') {
          const errors = Object.fromEntries(
            Object.entries(error.body.errors).map(([prop, errors]) => [
              prop,
              errors?.[0],
            ]),
          );

          setErrors(errors);
        } else if (error.body.code === 'BadRequestError') {
          setFormError(error.body.message);
        } else {
          setGlobalError(error);
        }
      } else {
        setGlobalError(error);
      }
    },
    [setErrors, setGlobalError, setFormError],
  );

  const handleSubmit: HandleSubmit<T> = useMemoizedThunkWeak(
    (callback) =>
      async ({ event }) => {
        event.preventDefault();
        const data = validate();

        if (!data) {
          return;
        }

        try {
          submitCounter.current++;
          setSubmitting(submitCounter.current > 0);
          await callback(data);
        } catch (error) {
          handleBackendError(error);
        } finally {
          submitCounter.current--;
          setSubmitting(submitCounter.current > 0);
        }
      },
    [validate, handleBackendError, setSubmitting],
  );

  return {
    controller,
    handleSubmit,
    register,
    submitting,
  };
}
