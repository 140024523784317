import { Button, Div, Flex, H1, H2 } from '@dnb/eufemia';
import { email as EmailIcon } from '@dnb/eufemia/icons';
import type { ApiWithOwnerDto } from '@portals/shared/admin/ApiDto';
import type { ScopeFromAllSourcesDto } from '@portals/shared/admin/ScopeDto';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { notifyAdminsToUpdateServiceNowInfo } from '@/api/apis';
import BackButton from '@/components/BackButton';
import LoadingModal from '@/components/LoadingModal';

import ApiListTable from './ApiListTable';

export default function ApiListWithoutSn(): JSX.Element {
  const { data: apis, isValidating: apisLoading } =
    useSWR<ApiWithOwnerDto[]>('/api');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { data: activeScopes, isValidating: isActiveScopeLoading } =
    useSWR<ScopeFromAllSourcesDto[]>('/scopes');

  const apisWithoutServiceNow = useMemo(() => {
    if (apis && activeScopes) {
      const scopesInPortal = activeScopes
        ?.filter(({ portalScope }) => portalScope)
        .map(({ portalScope }) => portalScope);
      const apiIds = new Set(scopesInPortal?.map((scope) => scope?.apiId));
      return apis.filter(
        ({ id, enableServiceNowRequest }) =>
          apiIds.has(id) && !enableServiceNowRequest,
      );
    }
    return [];
  }, [activeScopes, apis]);

  const isLoading = apisLoading || isActiveScopeLoading;

  if (isLoading || submitting) {
    return <LoadingModal />;
  }

  const notifyAdmins = async () => {
    setSubmitting(true);
    await notifyAdminsToUpdateServiceNowInfo(
      apisWithoutServiceNow.map(({ id }) => id),
    );
    setSubmitting(false);
  };

  return (
    <>
      <BackButton to="/apis">All apis</BackButton>
      <Flex.Container align="center" justify="space-between">
        <Div>
          <H1 bottom={0} top="large">
            APIs{' '}
          </H1>
          <H2>without servicenow integration</H2>
        </Div>

        <Button
          bottom="small"
          icon={EmailIcon}
          icon_position="left"
          onClick={() => notifyAdmins()}
          right="small"
          to="/apis/new"
        >
          Notify Api admins to provide ServiceNow info
        </Button>
      </Flex.Container>

      <ApiListTable apis={apisWithoutServiceNow} showFilters={false} />
    </>
  );
}
