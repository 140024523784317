import type { ApiInputDto } from '@portals/shared/admin/ApiDto';

import { request } from '@/request';

export function upsertApi(data: ApiInputDto): Promise<void> {
  return request('/api', 'post', data);
}
export function notifyAdminsToUpdateServiceNowInfo(
  apiIds: string[],
): Promise<void> {
  return request('/apis/notify-apis-without-sn', 'post', apiIds);
}

export function deleteApi(id: string): Promise<void> {
  return request(`/api/${id}`, 'delete');
}
